
import { defineComponent } from 'vue';
import { Select } from 'ant-design-vue';
import FilterSelection from '../FilterSelection.vue';

const { Option } = Select;

export default defineComponent({
  name: 'GeographicScope',
  components: {
    FilterSelection,
    Option,
  },
  props: {
    module: {
      type: String,
      default: 'surveysFilter',
      required: true,
    },
  },
  computed: {
    items() {
      const items = ['All', 'National', 'Regional'];
      if (this.module === 'surveysFilter' || this.module === 'surveysMortality') {
        items.push('Sub-National');
      }
      return items;
    },
    getGeographicScope() {
      return this.$store.getters[`${this.$props.module}/getGeographicScope`];
    },
  },
  methods: {
    selectItem(key: string) {
      this.$store.dispatch(`${this.$props.module}/updateGeographicName`, key);
    },
    clear() {
      this.$store.dispatch(`${this.$props.module}/updateGeographicName`, '');
      this.$store.dispatch(`${this.$props.module}/updateLocation`, '');
      this.$emit('clear');
    },
  },
  emits: ['clear'],
});
