import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Option = _resolveComponent("Option")!
  const _component_FilterSelection = _resolveComponent("FilterSelection")!

  return (_openBlock(), _createBlock(_component_FilterSelection, {
    label: "GEOGRAPHIC SCOPE",
    defaultName: "All",
    onClear: _ctx.clear,
    onSelect: _ctx.selectItem,
    state: _ctx.getGeographicScope || undefined
  }, {
    overlay: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_Option, { key: item }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item), 1)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["onClear", "onSelect", "state"]))
}