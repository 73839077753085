import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RangePicker = _resolveComponent("RangePicker")!
  const _component_FilterSelection = _resolveComponent("FilterSelection")!

  return (_openBlock(), _createBlock(_component_FilterSelection, {
    class: "date-range-filter",
    label: _ctx.label,
    onClear: _ctx.clear,
    state: _ctx.getDate.length > 0
  }, {
    dropdownBtn: _withCtx(() => [
      _createVNode(_component_RangePicker, {
        value: _ctx.getDate,
        format: _ctx.dateFormat,
        allowEmpty: [true, true],
        onCalendarChange: _ctx.calendarChange,
        allowClear: false,
        picker: _ctx.dateType,
        placeholder: _ctx.setDatePlaceholder,
        disabledDate: _ctx.disableDate
      }, {
        suffixIcon: _withCtx(() => [
          _createElementVNode("img", {
            src: require('@/assets/svg/Calendar.svg'),
            class: "calendar-icon"
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["value", "format", "onCalendarChange", "picker", "placeholder", "disabledDate"])
    ]),
    _: 1
  }, 8, ["label", "onClear", "state"]))
}