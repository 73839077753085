
import { defineComponent } from 'vue';
import {
  Select,
  Button,
} from 'ant-design-vue';
import { CaretDownFilled } from '@ant-design/icons-vue';

export default defineComponent({
  name: 'FilterSelection',
  components: {
    Select,
    CaretDownFilled,
    Button,
  },
  props: {
    label: {
      type: String,
    },
    defaultName: {
      type: String,
      required: false,
      default: 'All',
    },
    disable: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Function,
    },
    state: {
      type: String,
      default: undefined,
    },
  },
  emits: ['clear', 'select'],
});
