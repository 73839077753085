
import { defineComponent } from 'vue';
import { AutoComplete, Input } from 'ant-design-vue';
import { SearchOutlined } from '@ant-design/icons-vue';
import FilterSelection from '../FilterSelection.vue';

export default defineComponent({
  name: 'Location',
  components: {
    FilterSelection,
    AutoComplete,
    Input,
    SearchOutlined,
  },
  data() {
    return {
      value: '',
    };
  },
  props: {
    module: {
      type: String,
      default: 'surveysFilter',
      required: true,
    },
    options: {
      type: Array,
      require: true,
    },
  },
  computed: {
    getGeographicScope() {
      return this.$store.getters[`${this.$props.module}/getGeographicScope`];
    },
    getLocation() {
      return this.$store.getters[`${this.$props.module}/getLocation`];
    },
  },
  methods: {
    filterOption(inputValue: string, option: { value: string;}) {
      let input = inputValue;
      if (this.value === '') {
        input = '';
      }
      const regex = new RegExp(input, 'i');
      return option.value.match(regex);
    },
    selectItem(key: string) {
      this.value = key;
      this.$store.dispatch(`${this.$props.module}/updateLocation`, key);
    },
    clear() {
      this.$store.dispatch(`${this.$props.module}/updateLocation`, '');
      this.$emit('clear');
    },
  },
  emits: ['clear'],
  watch: {
    getLocation(newProps, old) {
      if (old && !newProps) {
        this.value = '';
      }
      if (this.value !== newProps) {
        this.value = newProps;
      }
    },
  },
});
