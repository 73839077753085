
import { defineComponent } from 'vue';
import { DatePicker } from 'ant-design-vue';
import dayjs, { Dayjs } from 'dayjs';
import FilterSelection from './FilterSelection.vue';

const { RangePicker } = DatePicker;

export default defineComponent({
  name: 'DateRangeFilter',
  components: {
    FilterSelection,
    RangePicker,
  },
  props: {
    module: {
      type: String,
      default: 'surveysFilter',
      require: true,
    },
    dateFormat: {
      type: String,
      default: 'YYYY-MM',
    },
    dateType: {
      type: String,
      default: 'month',
    },
    label: {
      type: String,
    },
  },
  computed: {
    getDate() {
      return this.$store.getters[`${this.$props.module}/getDate`];
    },
    setDatePlaceholder() {
      const placeholder = ['Start Date', 'End Date'];
      const d = this.$store.getters[`${this.$props.module}/getDate`];
      if (d.length > 0) {
        if (!d[0] && d[1]) {
          placeholder[0] = 'Up until';
        } else if (d[0] && !d[1]) {
          placeholder[1] = 'to Present';
        }
      }
      return placeholder;
    },
  },
  methods: {
    calendarChange(dates:Dayjs[]) {
      this.$store.dispatch(`${this.$props.module}/updateDate`, dates);
    },
    disableDate(currentDate: Dayjs): boolean {
      return dayjs().isBefore(currentDate);
    },
    clear() {
      this.$store.dispatch(`${this.$props.module}/updateDate`, []);
      this.$emit('clear');
    },
  },
  emits: ['clear'],
});
