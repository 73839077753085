
import { defineComponent } from 'vue';
import Location from './Location.vue';
import GeographicScope from './GeographicScope.vue';

export default defineComponent({
  name: 'GeographicLocation',
  components: {
    GeographicScope,
    Location,
  },
  props: {
    module: {
      type: String,
      default: 'surveysFilter',
      required: true,
    },
    locations: {
      type: Array,
      require: true,
    },
  },
  emits: ['clear'],
});
