import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GeographicScope = _resolveComponent("GeographicScope")!
  const _component_Location = _resolveComponent("Location")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_GeographicScope, {
      module: _ctx.module,
      onClear: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clear')))
    }, null, 8, ["module"]),
    _createVNode(_component_Location, {
      module: _ctx.module,
      options: _ctx.locations,
      onClear: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('clear')))
    }, null, 8, ["module", "options"])
  ], 64))
}